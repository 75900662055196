body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: #2b2d42;
  font-family: "Lato", sans-serif;
}
